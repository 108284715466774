import * as React from 'react';
import ReactDOM from 'react-dom';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Page, Language } from './types';

import './styles/main.less';

const App: React.FC<{}> = () => (
    <>
        <Router>
            <Switch>
                <Route exact path='/:lang/:page' component={Home} />
                <Route exact path='/'>
                    <div className='index'>
                        <div className='index-synthesis' />
                        <div className='links'>
                            <div className='link'>
                                <Link to={`/${Language.GR}/${Page.PORTFOLIO}`}>
                                    κωνσταντίνος
                                    <br />
                                    σισκάκης
                                </Link>
                            </div>
                            <div className='link'>
                                <Link to={`/${Language.EN}/${Page.PORTFOLIO}`}>
                                    konstantinos
                                    <br />
                                    siskakis
                                </Link>
                            </div>
                        </div>
                    </div>
                </Route>
            </Switch>
        </Router>
        <Footer />
    </>
);

ReactDOM.render(<App />, document.getElementById('root'));
