import React from 'react';
import * as Immutable from 'immutable';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from './Header';
import Contact from './Contact';
import Persona from './Persona';
import Portfolio from './Portfolio';
import { IPortfolioCategory, RouterProps, Page } from './types';
import Spatula from './Spatula';
import SpatulaCollection from './SpatulaCollection';

interface State {
    portfolioCategories: Immutable.List<IPortfolioCategory>;
    spatulaItemsCount: number;
    selectedSpatulaRangeIdx: number;
}

interface Props {
    portfolioCategories: Immutable.List<IPortfolioCategory>;
    spatulaItemsCount: number;
    selectedSpatulaRangeIdx: number;
    onLocationChange: () => void;
    onSetCategories: (categories: Immutable.List<IPortfolioCategory>) => void;
    onSetSpatulaItemsCount: (spatulaItemsCount: number) => void;
}

const MainBody: React.FC<Props> = ({
    onLocationChange,
    portfolioCategories,
    selectedSpatulaRangeIdx,
    onSetCategories,
    onSetSpatulaItemsCount
}) => {
    const location = useLocation();

    React.useEffect(() => {
        const { pathname } = location;

        if (
            !pathname.includes(Page.PORTFOLIO) &&
            !pathname.includes(Page.SPATULA_PROJECT_COLLECTION)
        ) {
            onLocationChange();
        }
    }, [location, onLocationChange]);

    return (
        <Switch>
            <Route
                exact
                path={`/:lang/${Page.PORTFOLIO}`}
                render={props => (
                    <Portfolio
                        {...props}
                        portfolioCategories={portfolioCategories}
                        onSetCategories={onSetCategories}
                    />
                )}
            />
            <Route exact path={`/:lang/${Page.PERSONA}`} component={Persona} />
            <Route exact path={`/:lang/${Page.SPATULA_PROJECT}`} component={Spatula} />
            <Route exact path={`/:lang/${Page.DOWNLOADS}`} component={undefined} />
            <Route exact path={`/:lang/${Page.CONTACT}`} component={Contact} />
            <Route
                exact
                path={`/:lang/${Page.SPATULA_PROJECT_COLLECTION}`}
                render={props => (
                    <SpatulaCollection
                        {...props}
                        selectedSpatulaRangeIdx={selectedSpatulaRangeIdx}
                        onSetSpatulaItemsCount={onSetSpatulaItemsCount}
                    />
                )}
            />
        </Switch>
    );
};

export default class Home extends React.Component<RouterProps, State> {
    state: State = {
        portfolioCategories: Immutable.List<IPortfolioCategory>(),
        spatulaItemsCount: 0,
        selectedSpatulaRangeIdx: 0
    };

    render() {
        const {
            portfolioCategories,
            spatulaItemsCount,
            selectedSpatulaRangeIdx
        } = this.state;
        const { lang, page } = this.props.match.params;

        return (
            <>
                <Header
                    language={lang}
                    page={page}
                    portfolioCategories={portfolioCategories}
                    spatulaItemsCount={spatulaItemsCount}
                    selectedSpatulaRangeIdx={selectedSpatulaRangeIdx}
                    onCategorySelectionChange={this.handleCategorySelectionChange}
                    onSpatulaItemChange={this.handleSpatulaItemChange}
                />
                <MainBody
                    selectedSpatulaRangeIdx={selectedSpatulaRangeIdx}
                    spatulaItemsCount={spatulaItemsCount}
                    portfolioCategories={portfolioCategories}
                    onLocationChange={this.handleLocationChange}
                    onSetCategories={this.handleSetCategories}
                    onSetSpatulaItemsCount={this.handleSetSpatulaItemsCount}
                />
            </>
        );
    }

    private handleSpatulaItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedSpatulaRangeIdx: Number(e.target.id) });
    };

    private handleCategorySelectionChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { portfolioCategories } = this.state;
        const id = e.target.value;
        const selected = e.target.checked;

        const categoryIdx = portfolioCategories.findIndex(
            cat => cat.get('id') === Number(id)
        );

        if (categoryIdx < 0) {
            return;
        }

        this.setState({
            portfolioCategories: portfolioCategories.update(categoryIdx, category =>
                category.set('selected', selected)
            )
        });
    };

    private handleSetCategories = (
        portfolioCategories: Immutable.List<IPortfolioCategory>
    ) => {
        this.setState({
            portfolioCategories,
            selectedSpatulaRangeIdx: 0,
            spatulaItemsCount: 0
        });
    };

    private handleSetSpatulaItemsCount = (spatulaItemsCount: number) => {
        this.setState({
            spatulaItemsCount,
            portfolioCategories: Immutable.List()
        });
    };

    private handleLocationChange = () => {
        this.setState({
            portfolioCategories: Immutable.List(),
            selectedSpatulaRangeIdx: 0,
            spatulaItemsCount: 0
        });
    };
}
