import * as React from 'react';
import { fetchData } from './Api';
import { SPATULA_IMAGE_ROOT_PATH, TEXTS } from './Constants';
import Lightbox from './Lightbox';
import { RouterProps, SpatulaCollectionItem } from './types';

interface Props extends RouterProps {
    selectedSpatulaRangeIdx: number;
    onSetSpatulaItemsCount: (spatulaItemsCount: number) => void;
}

interface State {
    spatulaItems: SpatulaCollectionItem[];
    lighboxOpen: boolean;
    selectedItemId: number;
}

export default class SpatulaCollection extends React.Component<Props, State> {
    state: State = {
        spatulaItems: [],
        lighboxOpen: false,
        selectedItemId: 0
    };

    render() {
        return (
            <div className='main-body spatula-collection'>
                <div className='collection'>
                    {this.state.spatulaItems.map(
                        ({ description, dimensions, image }, idx) => (
                            <div
                                className='grid-item'
                                key={idx}
                                onClick={this.handleGridItemClick(idx)}>
                                {/* eslint-disable-next-line jsx-a11y/alt-text*/}
                                <img
                                    src={`${image}_sm.jpg`}
                                    className='grid-item-image'
                                />
                                <div className='grid-item-description'>
                                    {description}
                                </div>
                                <hr />
                                <div
                                    className='grid-item-details'
                                    title={`Dimensions (H x W): ${dimensions}cm`}>
                                    {/* eslint-disable-next-line jsx-a11y/alt-text*/}
                                    <div className='dimensions-image' />
                                    {dimensions}cm
                                </div>
                            </div>
                        )
                    )}
                    {this.renderLightbox()}
                </div>
            </div>
        );
    }

    componentDidMount() {
        fetchData('/GetTotalSpatulasCount').then(this.props.onSetSpatulaItemsCount);
        this.fetchSpatulaItems();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedSpatulaRangeIdx !== this.props.selectedSpatulaRangeIdx) {
            this.fetchSpatulaItems();
        }
    }

    private fetchSpatulaItems() {
        fetchData('/GetSpatulas', {
            batchNumber: this.props.selectedSpatulaRangeIdx
        }).then(spatulas => {
            this.setState({
                spatulaItems: spatulas.map(
                    ({ imageName, originalHeightInCM, originalWidthInCM, id }) =>
                        ({
                            id,
                            description: imageName,
                            dimensions: `${originalHeightInCM}x${originalWidthInCM}`,
                            image: `${SPATULA_IMAGE_ROOT_PATH}/${imageName}`
                        } as SpatulaCollectionItem)
                )
            });
        });
    }

    private renderLightbox() {
        const { lighboxOpen, selectedItemId, spatulaItems } = this.state;

        if (!lighboxOpen || !spatulaItems.length) {
            return null;
        }

        const images = spatulaItems.map(({ image }) => `${image}_bg`);

        return (
            <Lightbox
                images={images}
                photoIndex={selectedItemId}
                caption={this.renderCaption}
                onCloseRequest={this.handleLightboxClose}
            />
        );
    }

    private renderCaption = (selectedPhotoIndex: number) => {
        const { spatulaItems } = this.state;
        const selectedItem = spatulaItems[selectedPhotoIndex];

        if (!selectedItem) {
            return null;
        }

        const imageCount =
            spatulaItems.length > 1
                ? TEXTS[this.props.match.params.lang].imageCaption(
                      selectedPhotoIndex,
                      spatulaItems.length
                  )
                : null;

        return (
            <div className='zoom-image-caption'>
                <div className='zoom-image-description spatula-collection'>
                    {selectedItem.description}
                </div>
                <div className='zoom-image-dimensions'>
                    Dimensions (H x W): {selectedItem.dimensions}cm
                </div>
                <div className='zoom-image-count'>{imageCount}</div>
            </div>
        );
    };

    private handleLightboxClose = () => {
        this.setState({
            lighboxOpen: false,
            selectedItemId: 0
        });
    };

    private handleGridItemClick = (id: number) => () => {
        this.setState({
            lighboxOpen: true,
            selectedItemId: id
        });
    };
}
