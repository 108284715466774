import * as Immutable from 'immutable';
import { RouteComponentProps } from 'react-router-dom';

export enum Language {
    GR = 'gr',
    EN = 'en'
}

export enum Page {
    PORTFOLIO = 'portfolio',
    PERSONA = 'persona',
    SPATULA_PROJECT = 'spatula-project',
    DOWNLOADS = 'downloads',
    CONTACT = 'contact',
    SPATULA_PROJECT_COLLECTION = 'spatula-collection'
}

export interface Store {
    lang: Language;
}

export interface RouterProps
    extends RouteComponentProps<{
        lang: Language;
        page: Page;
    }> {}

export interface PortfolioCategory {
    id: number;
    name: string;
    selected: boolean;
    imageFolderName: string;
    imageName: string;
}

export interface IPortfolioCategory extends Immutable.Map<string, any> {
    set<T extends keyof PortfolioCategory>(key: T, value: PortfolioCategory[T]): this;
    get<T extends keyof PortfolioCategory>(key: T): PortfolioCategory[T];
    toJS(): PortfolioCategory;
}

export interface PortfolioItem {
    id: number;
    image: string;
    description: string;
    categoryId: number;
    date: string;
    height: number;
    zoomImages?: string[];
    categoryImage?: string;
}

export interface SpatulaCollectionItem {
    id: number;
    image: string;
    description: string;
    dimensions: string;
}
