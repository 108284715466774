import * as React from 'react';
import Lightbox from 'react-image-lightbox';

interface Props {
    images: string[];
    caption: (selectedPhotoIndex: number) => React.ReactNode;
    photoIndex?: number;
    onCloseRequest: () => void;
}

interface State {
    selectedPhotoIndex: number;
}

const getPreviousIndex = (index: number, length: number): number =>
    (index + length - 1) % length;

const getNextIndex = (index: number, length: number): number => (index + 1) % length;

export default class LightboxComponent extends React.Component<Props, State> {
    state: State = {
        selectedPhotoIndex: this.props.photoIndex || 0
    };

    render() {
        const { images, caption, onCloseRequest } = this.props;
        const { selectedPhotoIndex } = this.state;

        return (
            <Lightbox
                wrapperClassName='lightbox-wrapper'
                animationDuration={500}
                animationOnKeyInput
                imageCaption={caption(selectedPhotoIndex)}
                mainSrc={images[selectedPhotoIndex]}
                nextSrc={this.getImage(1)}
                prevSrc={this.getImage(-1)}
                onMoveNextRequest={this.handleNextImage}
                onMovePrevRequest={this.handlePreviousImage}
                onCloseRequest={onCloseRequest}
            />
        );
    }

    private getImage(direction: 1 | -1) {
        const { selectedPhotoIndex } = this.state;
        const { images } = this.props;

        if (images.length === 1) {
            return undefined;
        }

        return direction === 1
            ? images[getNextIndex(selectedPhotoIndex, images.length)]
            : images[getPreviousIndex(selectedPhotoIndex, images.length)];
    }

    private handleNextImage = () => {
        this.setState(({ selectedPhotoIndex }) => ({
            selectedPhotoIndex: getNextIndex(
                selectedPhotoIndex,
                this.props.images.length
            )
        }));
    };

    private handlePreviousImage = () => {
        this.setState(({ selectedPhotoIndex }) => ({
            selectedPhotoIndex: getPreviousIndex(
                selectedPhotoIndex,
                this.props.images.length
            )
        }));
    };
}
