import * as React from 'react';
import { RouterProps } from './types';
import { TEXTS } from './Constants';
import FontAwesomeIcon from 'react-fontawesome';

const Contact: React.FC<RouterProps> = ({
    match: {
        params: { lang }
    }
}) => (
    <div className='main-body contact'>
        <div className='contact-logo' />
        <div className='contact-details main-body-details'>
            <div className='main-body-title'>{TEXTS[lang].contact}</div>
            <div className='details'>
                <div className='detail'>
                    <div className='caption'>web:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='http://www.grafist.eu'
                            target='_blank'
                            rel='noreferrer'>
                            www.grafist.eu
                        </a>{' '}
                        /{' '}
                        <a
                            className='link'
                            href='http://www.anagrafi.com'
                            target='_blank'
                            rel='noreferrer'>
                            www.anagrafi.com
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>facebook:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='https://www.facebook.com/anagrafi.fonts'
                            target='_blank'
                            rel='noreferrer'>
                            Anagrafi Fonts
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>twitter:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='https://twitter.com/triafonienta'
                            target='_blank'
                            rel='noreferrer'>
                            triafonienta
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>email:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='mailto:triafonienta@gmail.com'
                            target='_blank'
                            rel='noreferrer'>
                            triafonienta@gmail.com
                        </a>
                        <br />
                        <a
                            className='link'
                            href='mailto:info@anagrafi.com'
                            target='_blank'
                            rel='noreferrer'>
                            info@anagrafi.com
                        </a>{' '}
                        /{' '}
                        <a
                            className='link'
                            href='mailto:k.siskakis@grafist.eu'
                            target='_blank'
                            rel='noreferrer'>
                            k.siskakis@grafist.eu
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>issuu:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='https://issuu.com/anagrafi'
                            target='_blank'
                            rel='noreferrer'>
                            www.issuu.com/anagrafi
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>linkedin:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='https://www.linkedin.com/in/konstantinos-siskakis-00904a66'
                            target='_blank'
                            rel='noreferrer'>
                            Konstantinos Siskakis
                        </a>
                    </div>
                </div>
                <div className='detail'>
                    <div className='caption'>pinterest:</div>
                    <div className='value'>
                        <a
                            className='link'
                            href='https://www.pinterest.com/anagrafi/'
                            target='_blank'
                            rel='noreferrer'>
                            www.pinterest.com/anagrafi
                        </a>
                    </div>
                </div>
            </div>
            <div className='icons'>
                <a href='http://www.anagrafi.com' target='_blank' rel='noreferrer'>
                    <div className='link-icon web' />
                </a>
                <a
                    href='https://www.facebook.com/anagrafi.fonts'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon
                        className='link-icon facebook'
                        name='facebook-square'
                    />
                </a>
                <a
                    href='https://twitter.com/triafonienta'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon
                        className='link-icon twitter'
                        name='twitter-square'
                    />
                </a>
                <a href='https://issuu.com/anagrafi' target='_blank' rel='noreferrer'>
                    <div className='link-icon issuu' />
                </a>
                <a
                    href='https://www.linkedin.com/in/konstantinos-siskakis-00904a66'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon
                        className='link-icon linkedin'
                        name='linkedin-square'
                    />
                </a>
                <a
                    href='https://www.pinterest.com/anagrafi/'
                    target='_blank'
                    rel='noreferrer'>
                    <FontAwesomeIcon
                        className='link-icon pinterest'
                        name='pinterest-square'
                    />
                </a>
            </div>
        </div>
    </div>
);

export default Contact;
