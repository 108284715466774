import * as React from 'react';
import * as Immutable from 'immutable';
import { ORIGINS_IMAGE_ROOT_PATH, PORTFOLIO_IMAGE_ROOT_PATH, TEXTS } from './Constants';
import Masonry from './Masonry';
import Lightbox from './Lightbox';
import { fetchData } from './Api';
import {
    IPortfolioCategory,
    PortfolioCategory,
    PortfolioItem,
    RouterProps
} from './types';

interface Props extends RouterProps {
    portfolioCategories: Immutable.List<IPortfolioCategory>;
    onSetCategories: (categories: Immutable.List<IPortfolioCategory>) => void;
}

interface State {
    items: PortfolioItem[];
    lightboxOpen: boolean;
    selectedId: number | null;
}

export default class Portfolio extends React.Component<Props, State> {
    state: State = {
        items: [],
        lightboxOpen: false,
        selectedId: null
    };

    render() {
        return (
            <div className='main-body portfolio'>
                {this.renderLightbox()}
                {this.renderPortfolioItems()}
            </div>
        );
    }

    componentDidMount() {
        Promise.all([fetchData('/GetCategories'), fetchData('/GetDesigns')]).then(
            ([portfolioCategories, portfolioDesigns]: Record<string, any>[][]) => {
                const categories: PortfolioCategory[] = portfolioCategories.map(
                    ({ id, name, imageName, folderName }) => ({
                        id,
                        name,
                        imageName,
                        imageFolderName: folderName,
                        selected: true
                    })
                );

                this.props.onSetCategories(Immutable.fromJS(categories));

                const portfolioItems: PortfolioItem[] = portfolioDesigns.map(
                    ({
                        categoryID,
                        dateText,
                        description,
                        id,
                        mainImageName,
                        zooms,
                        height
                    }) => {
                        const { imageFolderName, imageName } = categories.find(
                            ({ id }) => id === categoryID
                        );

                        return {
                            categoryId: categoryID,
                            date: dateText,
                            description: description,
                            id: id,
                            image: `${PORTFOLIO_IMAGE_ROOT_PATH}/${imageFolderName}/${mainImageName}`,
                            zoomImages: zooms.map(
                                ({ imageName }) =>
                                    `${PORTFOLIO_IMAGE_ROOT_PATH}/${imageFolderName}/${imageName}`
                            ),
                            height: height,
                            categoryImage: `${ORIGINS_IMAGE_ROOT_PATH}/${imageName}`
                        };
                    }
                );

                this.setState({
                    items: portfolioItems
                });
            }
        );
    }

    private renderPortfolioItems() {
        const { items } = this.state;
        const { portfolioCategories } = this.props;

        const visibleCategories = portfolioCategories.filter(
            cat => !!cat.get('selected')
        );
        const visibleItems = items.filter(
            ({ categoryId }) =>
                !!visibleCategories.find(cat => cat.get('id') === categoryId)
        );

        return <Masonry list={visibleItems} onItemClick={this.handleItemClick} />;
    }

    private renderLightbox() {
        const { lightboxOpen, selectedId, items } = this.state;
        const data = items.find(({ id }) => id === selectedId);

        if (!lightboxOpen || !data || !data.zoomImages) {
            return null;
        }

        return (
            <Lightbox
                images={data.zoomImages}
                caption={this.renderImageCaption}
                onCloseRequest={this.handleLightboxClose}
            />
        );
    }

    private renderImageCaption = (selectedPhotoIndex: number) => {
        const { selectedId, items } = this.state;
        const { zoomImages, description } = items.find(({ id }) => id === selectedId);

        const imageCount =
            zoomImages.length > 1
                ? TEXTS[this.props.match.params.lang].imageCaption(
                      selectedPhotoIndex,
                      zoomImages.length
                  )
                : null;

        return (
            <div className='zoom-image-caption'>
                <div
                    className='zoom-image-description'
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <div className='zoom-image-count'>{imageCount}</div>
            </div>
        );
    };

    private handleLightboxClose = () => {
        this.setState({
            lightboxOpen: false,
            selectedId: null
        });
    };

    private handleItemClick = (id: number) => {
        this.setState({
            lightboxOpen: true,
            selectedId: id
        });
    };
}
