import * as React from 'react';
import * as Immutable from 'immutable';
import { range } from 'lodash';
import { IPortfolioCategory, Language, Page } from './types';
// import FontAwesomeIcon from 'react-fontawesome';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { AVAILABLE_LANGUAGES } from './Constants';

const SPATULA_CHUNK_SIZE = 100;

interface Props {
    language: Language;
    page: Page;
    portfolioCategories: Immutable.List<IPortfolioCategory>;
    spatulaItemsCount: number;
    selectedSpatulaRangeIdx: number;
    onCategorySelectionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSpatulaItemChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Categories: React.FC<Props> = ({
    portfolioCategories,
    onCategorySelectionChange
}) => (
    <div className='header-items'>
        {portfolioCategories.map(category => (
            <div className='header-item' key={category.get('id')}>
                <input
                    type='checkbox'
                    checked={category.get('selected')}
                    value={category.get('id')}
                    onChange={onCategorySelectionChange}
                />
                <div className='header-caption'>{category.get('name')}</div>
            </div>
        ))}
    </div>
);

const SpatulaItems: React.FC<Props> = ({
    spatulaItemsCount,
    selectedSpatulaRangeIdx,
    onSpatulaItemChange
}) => {
    const chunks = range(0, spatulaItemsCount, SPATULA_CHUNK_SIZE);

    return (
        <div className='header-items'>
            {chunks.map((chunk, idx) => {
                const endRange = (idx + 1) * SPATULA_CHUNK_SIZE;
                const startRange = chunk + 1;

                const endRangeCaption =
                    endRange > spatulaItemsCount ? spatulaItemsCount : endRange;

                return (
                    <div className='header-item' key={idx}>
                        <input
                            type='radio'
                            id={idx.toString()}
                            name='spatula-item-range'
                            checked={selectedSpatulaRangeIdx === idx}
                            onChange={onSpatulaItemChange}
                        />
                        <div className='header-caption'>
                            {startRange}
                            {endRangeCaption === startRange
                                ? null
                                : ` - ${endRangeCaption}`}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const MenuLinks: React.FC<Props> = ({ language, page }) => (
    <div className='menu-items'>
        {Object.values(Page).map(key =>
            key !== Page.SPATULA_PROJECT_COLLECTION ? (
                <Link to={`/${language}/${key}`} key={key}>
                    <div className='menu-item-wrapper'>
                        <div
                            className={classnames('menu-item', key, {
                                selected: page === key
                            })}>
                            {page === key ? <div className='selected-stripe' /> : null}
                        </div>
                    </div>
                </Link>
            ) : null
        )}
    </div>
);

const Languages: React.FC<Props> = ({ language, page }) => (
    <div className='languages'>
        {AVAILABLE_LANGUAGES.map(({ language: lang, caption, title }, i) => (
            <Link to={`/${lang}/${page}`} key={i} className='language-link'>
                <span
                    className={classnames('language', {
                        selected: lang === language
                    })}
                    title={title}>
                    {caption}
                </span>
                {i < AVAILABLE_LANGUAGES.length - 1 && '/'}
            </Link>
        ))}
    </div>
);

const Header: React.FC<Props> = props => (
    <div className='header'>
        <div className={classnames('logo', props.page)} />
        <div className='header-content'>
            <div className='thick-line' />
            <div className='main-menu'>
                <div className='left'>{<MenuLinks {...props} />}</div>
                <div className='right'>
                    {/* <div className='search-menu'>
                        <input placeholder='Search' className='search-input' />
                        <FontAwesomeIcon name='search-plus' className='search' />
                    </div> */}
                    <Languages {...props} />
                </div>
            </div>
            <div className='thick-line' />
            {!!props.portfolioCategories.size && <Categories {...props} />}
            {!!props.spatulaItemsCount && <SpatulaItems {...props} />}
        </div>
    </div>
);

export default Header;
