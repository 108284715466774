import * as React from 'react';
import {
    Masonry,
    CellMeasurerCache,
    AutoSizer,
    Size,
    CellMeasurer
} from 'react-virtualized';
import {
    createCellPositioner,
    MasonryCellProps
} from 'react-virtualized/dist/es/Masonry';
import { PortfolioItem } from './types';

interface Props {
    list: PortfolioItem[];
    onItemClick: (id: number) => void;
}

const GUTTER_SIZE = 15;
const COL_WIDTH = 220;
const COL_HEIGHT = 200;

export default class MasonryComponent extends React.Component<Props> {
    private columnCount: number = 0;
    private masonry = React.createRef<Masonry>();

    private cache = new CellMeasurerCache({
        defaultHeight: COL_HEIGHT,
        defaultWidth: COL_WIDTH,
        fixedWidth: true
    });

    private cellPositioner = createCellPositioner({
        cellMeasurerCache: this.cache,
        columnCount: this.columnCount,
        columnWidth: COL_WIDTH,
        spacer: GUTTER_SIZE
    });

    render() {
        return (
            <AutoSizer onResize={this.handleResize}>
                {({ width, height }) => (
                    <Masonry
                        autoHeight={false}
                        cellCount={this.props.list.length}
                        cellMeasurerCache={this.cache}
                        cellPositioner={this.cellPositioner}
                        cellRenderer={this.renderCell}
                        height={height}
                        ref={this.masonry}
                        width={width}
                    />
                )}
            </AutoSizer>
        );
    }

    componentDidUpdate() {
        this.cache.clearAll();
        this.resetCellPositioner();
        this.masonry.current?.clearCellPositions();
    }

    private renderCell = ({ index, parent, key, style }: MasonryCellProps) => {
        const data = this.props.list[index];

        if (!data) {
            return null;
        }

        const { description, image, id, height, categoryImage, date } = data;

        return (
            <CellMeasurer cache={this.cache} rowIndex={index} key={key} parent={parent}>
                <div
                    className={`grid-item ${id}`}
                    style={{
                        ...style,
                        width: COL_WIDTH
                    }}
                    onClick={this.handleItemClick(id)}>
                    {/* eslint-disable-next-line jsx-a11y/alt-text*/}
                    <img
                        src={image}
                        className='grid-item-image'
                        style={{
                            height,
                            width: COL_WIDTH
                        }}
                    />
                    <div
                        className='grid-item-description'
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <hr />
                    <div className='grid-item-details'>
                        {/* eslint-disable-next-line jsx-a11y/alt-text*/}
                        <img src={categoryImage} className='category-image' />
                        {date}
                    </div>
                </div>
            </CellMeasurer>
        );
    };

    private handleItemClick = (id: number) => () => {
        this.props.onItemClick(id);
    };

    private handleResize = ({ width }: Size) => {
        this.calculateColumnCount(width);
        this.resetCellPositioner();
        this.masonry.current?.recomputeCellPositions();
    };

    private calculateColumnCount(width: number) {
        this.columnCount = Math.floor(width / (COL_WIDTH + GUTTER_SIZE));
    }

    private resetCellPositioner() {
        this.cellPositioner.reset({
            columnCount: this.columnCount,
            columnWidth: COL_WIDTH,
            spacer: GUTTER_SIZE
        });
    }
}
