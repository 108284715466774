import { Language } from './types';

export const API_ROOT_PATH =
    'https://personalizedgenericapi.azurewebsites.net/api/Grafist';
export const PORTFOLIO_IMAGE_ROOT_PATH =
    'https://d8lc6s7bpjqw2.cloudfront.net/grafist/images/portfolio/categories';
export const ORIGINS_IMAGE_ROOT_PATH =
    'https://d8lc6s7bpjqw2.cloudfront.net/grafist/images/portfolio/origins';
export const SPATULA_IMAGE_ROOT_PATH =
    'https://d8lc6s7bpjqw2.cloudfront.net/grafist/images/spatulaProject/collection';

export const AVAILABLE_LANGUAGES = [
    {
        language: Language.GR,
        caption: 'ε',
        title: 'Ελληνικά'
    },
    {
        language: Language.EN,
        caption: 'e',
        title: 'English'
    }
];

export const TEXTS = {
    [Language.GR]: {
        contact: 'Επαφή',
        persona: {
            title: 'Ιστορία',
            details:
                'ΓΕΝΝΗΘΗΚΕ στην Κρήτη.\n' +
                '<p>Στα 17, σχεδίασε τα πρώτα του (φανταστικά) λογότυπα. Η γραφή, τα γράμματα και η τυπογραφία τον συνεπήραν αργότερα. ' +
                'Μελέτησε διεξοδικά τη βυζαντινή γραφή και σχεδίασε πολλές βυζαντινές, τυπογραφικές αλλά και μοντέρνες γραμματοσειρές. ' +
                'Στη Θεσσαλονίκη, σπούδασε Γραφιστική στον «Όμιλο Δημητρέλλη».</p>\n' +
                '<p>Το 1994 είχε την τύχη να γνωρίσει το τυπογραφικό εργαστήρι «Γράμμα». Ξεκίνησε μαζί με τον Θ. Βαρθολομαίο το στήσιμο ' +
                'ενός υποτυπώδους δημιουργικού ατελιέ για την κάλυψη των αναγκών του τυπογραφείου. Η συνέχεια, 20 και πλέον χρόνια μετά, ' +
                'είναι λίγο πολύ γνωστή. Ένα πλήρες δημιουργικό τμήμα και μια σειρά εργασιών σε ολόκληρο το φάσμα των γραφικών τεχνών.</p>\n' +
                '<p>Μεγάλες αγάπες παραμένουν η μουσική, η φωτογραφία και ο κινηματογράφος, και η σχεδίαση γραμμάτων.</p>',
            pdf: {
                text: 'Μπορείτε να κατεβάσετε ένα βιογραφικό σημείωμα,',
                link: 'εδώ'
            }
        },
        spatula: {
            collection: 'Η Συλλογή',
            details:
                '<p>Το <b>Spatula Project</b> είναι μια προσπάθεια που κράτησε είκοσι δύο ολόκληρα χρόνια. Αποτελεί τη συλλογή αποτυπωμάτων τυπογραφικού μελανιού ' +
                'πάνω σε μια πληθώρα χαρτιών, που άλλοτε είχε χρηστική σημασία και άλλοτε όχι. Ο τυπογράφος, στην προσπάθειά του να ετοιμάσει το ζητούμενο χρώμα, ' +
                'χρειάζεται πάντα ένα κομμάτι χαρτί, το οποίο λειτουργεί σαν βάση παρασκευής. Πάνω εκεί αναμειγνύει με την τυπογραφική σπάτουλα (εξ ού και το όνομα ' +
                'της συλλογής) τα κατάλληλα ποσοστά πρωταρχικών χρωμάτων ώστε να θα δώσουν το τελικό χρώμα (Pantone). Έτσι, το χαρτί αυτό, παίζει το ρόλο ενός ' +
                '«ζωγραφικού καμβά», πάνω στον οποίο ο τυπογράφος-τεχνίτης δημιουργεί τυχαία –τονίζω αυτό το τυχαία– αλλόκοτα, αισθησιακά, ονειρικά, φανταστικά, ' +
                'συμπλέγματα χρωμάτων. Χρωματικά μοτίβα που δεν μπορούν να δημιουργηθούν με καμία εκούσια προσπάθεια – προκύπτουν πάντα στην τύχη! Είναι, δηλαδή, ' +
                'μια τέχνη της τυχαιότητας (random art).</p>\n' +
                '<p>Το «δειγματολόγιο» των χαρτιών αποτελεί μια γιγαντιαία συλλογή ειδών, κατηγοριών, μεγεθών και ποιοτήτων που αντικατοπτρίζουν απλά, το εύρος των ' +
                'εργασιών που τυπώνει ένα καλλιτεχνικό τυπογραφείο στη διάρκεια δύο δεκαετιών. Από το πιο απλό, φθηνό και ταπεινό χαρτί, μέχρι το χειροποίητο, πανάκριβο ' +
                'χαρτί εισαγωγής, η αποτύπωση είναι μία: τυχαία και χωρίς καμία εικαστική σκοπιμότητα. Μόνο τη δημιουργία του κατάλληλου χρώματος για την εκτύπωση. Χαρτί ' +
                'τυπωμένο, διπλο- και τριπλοτυπωμένο, χαρτί με εικαστικά στοιχεία, χαρτί με εικόνες, σχήματα, αφηρημένες έννοιες, μετά την «επέλαση» του μελανιού. Ένα ' +
                'μυστήριο θαύμα. Κάθε ένα κομμάτι, από το πιο μικρό των 10x10εκ. μέχρι το μεγαλύτερο των 35x50εκ., είναι τόσο μοναδικό, που αποτελεί ένα μικρό (ή και ' +
                'μεγάλο) «έργο τέχνης»!</p>\n' +
                '<p>Η συλλογή αυτή, όταν άρχισε να δείχνει το μέγεθος και την αξία της, ψηφιοποιήθηκε σε υπερηψηλή ανάλυση και αρχειοθετήθηκε με σκοπό την πώλησή της ' +
                '(χαρτί και ηλεκτρονικό αρχείο) σε οποίον μπορεί να κατανοήσει το μέγεθος αυτής της ιδιαίτερης καλλιτεχνικής αξίας. Όλη τη συλλογή, μπορείτε να βρείτε ' +
                'στο σύνδεσμο <b>“Η Συλλογή”</b>.</p>'
        },
        imageCaption: (index: number, length: number) =>
            `Εικόνα ${index + 1} από ${length}`
    },
    [Language.EN]: {
        contact: 'Contact',
        persona: {
            title: 'History',
            details:
                'BORN in Creta isl., Greece.\n' +
                '<p>At the age of 17, he designed his first (imaginary) logos. Paleography, glyphs and typography fascinated him later. ' +
                'He studied thoroughly the byzantine writing and designed a lot of byzantine, typographic but also modern fonts. ' +
                'He studied graphic design in the "Dimitrelli" School, in Thessaloniki.</p>\n' +
                '<p>In 1994 he was lucky enough to join the typographic workshop "Gramma". He started with Th. Vartholomeos the setup ' +
                'of a creative studio to cover the needs of the printing house. All the rest, more than 20 years later, ' +
                'is more or less known. A fully creative department and a series of works across the full range of graphic arts.</p>\n' +
                '<p>Fonts design, music, photography and cinema still remain objects of great desire.</p>',
            pdf: {
                text: 'You can download the Curriculum Vitae,',
                link: 'here'
            }
        },
        spatula: {
            collection: 'The Collection',
            details:
                '<p>The <b>Spatula Project</b> is an effort that lasted 20 whole years. It consists of a selection of imprints of typography ink on a wide range of papers, ' +
                'that sometimes can be of use while others not. While trying to prepare the necessary color, the typographer always needs a piece of paper that is used ' +
                'as a production base. On that paper, he mixes the suitable primary colors with the typography spatula (thus the collection name) so he can get the final color (Pantone). ' +
                'So, this paper, has the role of a "painting canvas", on which the typographer creates random, bizarre, sensual, dreamy, fantastic color clusters. Color motifs ' +
                'that cannot be created on purpose – they are always randomly created! They are, what we call, a "Random Art".</p>\n' +
                '<p>The paper "samples" is a wide collection of different categories, sizes and qualities of paper that merely reflect the range of works that an artistic ' +
                'printing shop prints in two decades: from the simplest, cheapest and most humble paper to the handmade, most expensive imported paper, the imprint is one: ' +
                'random and without any artistic purpose. It aims only to the creation of the necessary color for the printing. A paper printed twice and thrice, a paper with artistic elements, ' +
                'a paper with images, shapes, abstract concepts after the ink "charge". A mysterious miracle! Each piece, from the smallest 10x10cm to the biggest 35x50cm is so unique that could ' +
                'be considered a small (or big) "piece of art"!</p>\n' +
                '<p>When its size and value started showing, this collection was digitized in super high resolution and saved so that it can be sold (paper and electronic files) ' +
                'to whomever can understand its special artistic value. You can view the complete collection under <b>"The Collection"</b> link.</p>'
        },
        imageCaption: (index: number, length: number) =>
            `Image ${index + 1} of ${length}`
    }
};
