import * as React from 'react';
import { Link } from 'react-router-dom';
import { TEXTS } from './Constants';
import { RouterProps, Page } from './types';

const Spatula: React.FC<RouterProps> = ({
    match: {
        params: { lang }
    }
}) => (
    <div className='main-body spatula'>
        <div className='spatula-logo' />
        <div className='spatula-details main-body-details'>
            <div className='main-body-title'>The Spatula Project</div>
            <div className='spatula-body'>
                <div
                    className='details'
                    dangerouslySetInnerHTML={{ __html: TEXTS[lang].spatula.details }}
                />
                <Link
                    to={`/${lang}/${Page.SPATULA_PROJECT_COLLECTION}`}
                    className='collection-link'>
                    {TEXTS[lang].spatula.collection}
                </Link>
            </div>
        </div>
    </div>
);

export default Spatula;
