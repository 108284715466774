import * as React from 'react';
import { RouterProps } from './types';
import { TEXTS } from './Constants';
import FontAwesomeIcon from 'react-fontawesome';

const Persona: React.FC<RouterProps> = ({
    match: {
        params: { lang }
    }
}) => (
    <div className='main-body persona'>
        <div className='persona-logo' />
        <div className='persona-details main-body-details'>
            <div className='main-body-title'>{TEXTS[lang].persona.title}</div>
            <div
                className='details'
                dangerouslySetInnerHTML={{ __html: TEXTS[lang].persona.details }}
            />
            <div className='cv'>
                <FontAwesomeIcon name='file-pdf-o' className='pdf-icon' />
                <div className='cv-link'>
                    {TEXTS[lang].persona.pdf.text}{' '}
                    <a href='/downloads/Kostantinos_Siskakis_CV_gr.pdf' download>
                        {TEXTS[lang].persona.pdf.link}
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default Persona;
