import * as React from 'react';
import moment from 'moment-timezone';

const Footer: React.FC = () => (
    <div className='footer'>
        <hr />
        <span className='footer-info'>
            &copy; Konstantinos Siskakis 2020. All rights reserved. Design &amp;
            Development:{' '}
            <a href='https://personalized.at/' target='_blank' rel='noreferrer'>
                personalized.at
            </a>
            . {moment().format('dddd, DD MMMM YYYY')}
        </span>
    </div>
);

export default Footer;
